
/* MAIN CONTENT */
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  margin-top: 4rem;
  width: 100%;
  max-width: 800px;
}

section:first-child {
  padding-top: 10vw;
  padding-bottom: 5vw;
  max-width: 100vw;
  height: fit-content;
}
