footer {
  margin-top: 6rem;
  height: max-content;
}

.footer-container {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-primary);
  height: fit-content;
  min-height: 100px;
}

.background-footer {
  background-image: url("../../assets/stacked-waves-footer.svg");
}

.links {
  height: max-content;
}

.links li {
  padding: 1rem 0;
  text-align: center;
}

.links a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.links p {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--font-color-primary);
  text-shadow: 1px 1px 1px var(--font-color-secondary);
}

.links span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background: white;
  border-radius: 18px;
  box-shadow: var(--box-shadow-big);
}

.links img {
  height: 24px;
}

@media screen and (min-width:  769px) {
  ul {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}
