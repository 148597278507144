.about {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--font-color-primary);
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  text-shadow: var(--text-shadow);
  padding-top: 0.75rem;
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-blue-primary);
  box-shadow: var(--box-shadow-big);
}

.about-image {
  margin-bottom: -4px;
  max-width: 400px;
}

.about-image img{
  height: 100%;
  width: 50vw;
  height: auto;
}

.about-text-container {
  height: fit-content;
  background: var(--font-color-tertiary);
  margin-left: -2rem;
  margin-right: 2rem;
  box-shadow: var(--box-shadow-big);
  padding: 1rem;
}

.about-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  color: var(--font-color-primary);
  background-clip: text;
}

@media screen and (min-width: 769px) {
  .about-image img {
    width: 100%;
  }

  .about-text {
    font-size: 1.5rem;
  }
}
