.mobile-navigation {
  display: none;
}

header {
  width: 100vw;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  background-color: #0088ffa2;
  z-index: 10;
}

header img {

  height: 2.5rem;
}

header img:hover {
  cursor: pointer;
}

.nav-container {
  height: 100%;
}

header nav {
  display: flex;
  align-items: center;
  height: 100%;
}

header ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

header li {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--font-color-tertiary);
  font-weight:bold;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}

header a {
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navigation {
    display: none;
  }

  .mobile-navigation {
    display: flex;
  }

  .navbar-light {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.92);
    flex-direction: column;
    height: 100vh;
    left: 0;
    bottom: -100vh;
  }

  .navbar-light li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 1.5rem;
    margin: 2rem 0;
    width: 100vw;
  }

  .navbar-light li:hover {
    background-color: #C8D5FF;
  }

  .navbar-light a {
    height: fit-content;
  }

  .hamburger {
    padding-right: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  header {
    height: 4rem;
    background-color: #0088ff;
  }

  header img, header ul{
    margin: 0 2rem;
  }

  header img {
    padding-top: 1rem;
    height: 5rem;
  }

  header li {
    font-size: 1rem;
  }

}
