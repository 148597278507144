.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.projects-title {
  font-size: 2rem;
  color: var(--font-color-primary);
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  background: var(--color-background-white);
  padding: 0.5rem;
  padding-top: 0.75rem;
  margin-bottom: 0.5rem;
  text-shadow: var(--text-shadow);
}

.projects-container{
  width: 100%;
  padding: 2rem 0;
  background-color: var(--color-blue-secondary);
  box-shadow: var(--box-shadow-big);
}

@media screen and (min-width: 769px) {

  .project-container {
    display: flex;
  }

  /* .project-container {
    flex-direction: row-reverse;
  } */

}

